import React from "react";
import TableCell from '@mui/material/TableCell';

const ChallengesActivityGroupStatusCell = (props) => {
  const status = props.status

  const ltGreen = '#82B11D'
  const dkBlue = '#406AB4'
  const errorRed = '#D32F2F'
  const neutralGrey = '#B0BEC5'
  const warningYellow = '#FFCC00'

  const negativeEndStates = [
    "Dismissed",
    "Inv. Expired",
    "Expired",
    "Uninstalled"
  ]

  const startingStates = [
    "Inv. Locked",
    "Unlocking",
    "Queued"
  ]

  const partialStates = [
    "Completed",
    "Partially Completed"
  ]

  const challengeStatusCellSyles = {
    backgroundColor: 'rgb(214 214 214)',
    // minWidth: 150,
    justifyContent: 'flex-start',
    minHeight: 33,
    display: 'flex',
    borderRadius: 25,
    alignItems: 'center',
    paddingLeft: '5px',
    maxHeight: '35px'
  }

  const circleStyle = {
    width: 25,
    height: 25,
    backgroundColor: chooseCircleColor(),
    borderRadius: 15,
    position: 'relative'
  }

  function chooseCircleColor(){
    let color = ltGreen
    if (negativeEndStates.includes(status)){color = errorRed}
    if (startingStates.includes(status)){color = warningYellow}
    if (partialStates.includes(status)){color = dkBlue}
    if (status === "Unlocked"){ color = ltGreen }
    return color
  }

  return(
    <TableCell>
      <div style={{display:'flex', justifyContent: 'center'}}>
        <div style={challengeStatusCellSyles}>
          <div style={circleStyle}></div>
          <p style={{margin: "auto", marginLeft:'8px', paddingRight: '10px'}}>{status}</p>
        </div>
      </div>

    </TableCell>
  )
}

export default ChallengesActivityGroupStatusCell;