import {useEffect, useState} from 'react';
import IMLoader from "../../../common/IMLoader";
import { getPlayerActivityGroups } from "../../../../models/Player/ChallengeActivityGroups";

import ChallengesTable from "./ChallengesTable";
import { ChallengesContext } from "./ChallengesContext";
import ChallengeFilters from "./ChallengeFilters";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const ChallengesContainer = () => {
  const [challengeGroups, setChallengeGroups] = useState([])
  const [shownChallengeGroups, setShownChallengeGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [refreshTrigger, setRefreshTrigger] = useState(false)

  useEffect(()=>{
    getPlayerActivityGroups().then((activityGroups)=>{
      console.log("Boost API Response:", activityGroups)
      setInitialStates(activityGroups)
      setLoading(false)
      setRefreshTrigger(false)
    }).catch((error)=>{
      console.error(error)
      setLoading(false)
      setRefreshTrigger(false)
    })

  },[refreshTrigger])

  function setInitialStates(activityGroups){
    setChallengeGroups(activityGroups)
    setShownChallengeGroups(activityGroups)
  }

  function refresh(){
    setLoading(true)
    setRefreshTrigger(true)
  }

  function linkToPlaymax(){
    const pathname = window.location.pathname.split('/');
    const playerId = pathname[2];
    return `https://playmax.influencemobile.services/boost/players/${playerId}`;
  }

  const noContentStyles = {
    fontSize: 20,
    color: 'grey',
    width: 'inherit',
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: 20
  }

  return (
  <>
    { loading &&
        <IMLoader message={"Loading Boost Challenges..."} mini={true}/>
    }
    {
      challengeGroups.length > 0 && !loading &&
      <ChallengesContext.Provider value={{
        challengeGroups,
        setChallengeGroups,
        shownChallengeGroups,
        setShownChallengeGroups
      }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "calc(100% - 300px)"
          }}
        >
          <ChallengeFilters
            refreshAction={refresh}
          />
          <Button
            variant="outlined"
            href={linkToPlaymax()}
            sx={{textTransform: 'uppercase'}}
          >View In Playmax</Button>
        </Stack>
        <ChallengesTable></ChallengesTable>
      </ChallengesContext.Provider>
    }
    {
      shownChallengeGroups.length === 0 && !loading &&
      <div style={noContentStyles}>No Boost Challenges to Show</div>
    }
  </>
  )
}

export default ChallengesContainer